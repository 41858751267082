export const AUTH_LAYOUT = "/auth";
export const LOGIN = "/login";

export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";

export const ADMIN_LAYOUT = "/admin";
export const SUB_ADMIN_LAYOUT = "/subadmin";

export const DASHBOARD = "/dashboard";
export const CLIENT_MANAGEMENT = "/client-mgt";
export const CLIENT_PERSONAL_DETAILS = "/client-personal-details";
export const FRESH_APPOINTMENT_DETAILS = "/fresh-appointment-details";
export const FOLLOWUP_APPOINTMENTS_DETAILS =
  "/client-followup-appointment-details";

export const LAWYERS_MANAGEMENT = "/lawyers-mgt";
export const ADD_NEW_LAWYER_PERSONAL = "/add-new-lawyer-personal";
export const ADD_NEW_LAWYER_PROFESSIONAL = "/add-new-lawyer-professional";
export const LAWYER_DETAILS = "/lawyer-details";
export const APPROVED_DETAILS = "/view-approved-details";
export const ADD_NEW_LAWYER_PAYMENT = "/add-new-lawyer-payment";

export const OTHER = "/other";
export const NEW_REQUEST_DETAILS = "/new-request-details";
export const APPROVED_REQUEST_DETAILS = "/approved-request-details";
export const APPOINTMENT_DETAILS = "/appointment-details";

export const SERVICE = "/services";
export const AGENCY_TYPE = "/agency-type";
export const LEGAL_DOCUMENTS = "/legal-documents";
export const LEGAL_DOCUMENT_UPDATE = "/legal-document-update";
// export const VIEW_LEGAL_DOCUMENT  = '/view-legal-document';
export const LEGAL_DOCUMENT_VIEW = "/legal-document-view";

export const APPOINTMENTS_SLOT = "/appointments-slot";
export const APPOINTMENTS = "/appointments";
export const APPOINTMENTS_VIEW = "/appointments-view";
export const APPOINTMENTS_FOLLOW_DETAILS = "/follow-appointments-details";

export const CMS = "/cms";
export const VIEW_CONTENT = "/view-content";
export const EDIT_CONTENT = "/edit-content";
export const FAQ_LIST = "/faq-list";
export const ADD_FAQ = "/add-faq";
export const VIEW_FAQ = "/view-faq";
export const EDIT_FAQ = "/edit-faq";
export const EARNINGS = "/earnings";
export const PAYMENTS = "/payments";
export const VIEW_PAYMENT = "/view-payment";
export const INVOICES = "/invoices";
export const VIEW_INVOICE = "/view-invoice";
export const SUB_ADMIN_LIST = "/sub-admin-list";
export const ADD_NEW_SUB_ADMIN = "/add-new-sub-admin";
export const VIEW_SUB_ADMIN = "/view-sub-admin";
export const EDIT_SUB_ADMIN = "/edit-sub-admin";
export const NOTIFICATION = "/notification";
export const VIEW_NOTIFICATION = "/view-notification";
export const WRITE_A_MESSAGE = "/write-a-message";
export const MESSAGE_DETAIL_LIST = "/message-details-list";
export const MESSAGE_VIEW = "/message-view";
export const SETTINGS = "/change-password";
export const VIEW_LAWYER_APPOINTMENT_DETAILS =
  "/view-lawyer-appointment-details";
export const VIEW_LAWYER_FOLLOWUP_APPOINTMENT_DETAILS =
  "/view-lawyer-followup-appointment-details";
export const LAWYER_APPOINTMENTLIST = "/lawyer-appointment-list";
export const PROFILE = "/profile";
export const QUERY_MANAGEMENT = "/query-management";
export const VIEW_QUERY_MANAGEMENT = "/view-query-management";

import React, { Component, lazy, Suspense } from 'react';
import { createBrowserHistory } from "history";
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';


// import ApprovedDetails from '../components/Lawyers/ApprovedDetails';
// import FreshAppointmentDetails from '../components/clients/FreshAppointmentDetails';
// import FollowUpAppointmentDetails from '../components/clients/FollowUpAppointmentDetails';
// import AppointmentsSlot from '../components/appointments-slot/appointments-slot';
// import Appointments from '../components/appointments/appointments';
// import ViewAppointmentsDetails from '../components/appointments/view-appointment-details';
// import FollowAppointmentsDetails from '../components/appointments/follow-up-appointment-details';
// import Cms from '../components/cms/content-management';

import { ADMIN_LAYOUT, AUTH_LAYOUT } from '../config/RouterConfig';
import PrivateRoute from './PrivateRoute';
import SpinnerLoader from '../commonServices/utils/Loader';

const AuthLayout = lazy(() => import('../container-layout/AuthLayout'));
const AdminLayout = lazy(() => import('../container-layout/AdminLayout'));
const history = createBrowserHistory();

class RouteManagement extends Component {

    

    render() {

        return (
            <Router history={history}>
                <Suspense fallback={<SpinnerLoader />}>
                    <Switch>
                        <Route path={AUTH_LAYOUT} component={AuthLayout} />
                        <PrivateRoute path={ADMIN_LAYOUT} component={AdminLayout} />
                        <Redirect from="/" to={AUTH_LAYOUT} />
                    </Switch>
                </Suspense>
            </Router>
        )
    }

}

export default RouteManagement
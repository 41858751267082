import { PERMISSION, USER_TYPE, USER_PROFILE } from "../action/ActionTypes"


const initialState = {
    userType : "",
    token : "",
    permission : {},
    profileData:{}
}

const getPermission = (state= initialState , action )=>{
     switch (action.type){
       case PERMISSION : return {
          ...state,
          permission : action.payload.permission 
       }
       case USER_TYPE : return{
           ...state,
         token : action.payload.token,
         userType :  action.payload.userType
       }
       case USER_PROFILE : return{
        ...state,
      profileData : action.payload.profileData
    }

       default: return initialState
     }
}

export default getPermission;
import React from 'react'
import { Spinner } from 'reactstrap';

function SpinnerLoader() {
    
    return (
        <div className = "loader">
            <div className = "d-flex flex-column">
              {/* <img style ={{width:100, height:100}}  alt="loader" src="/images/logo-loader.png" /> */}
                <label >
                <Spinner color="success" />
                </label>
            </div>
        </div>
    )
}

export default SpinnerLoader

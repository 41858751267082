import './App.css';
import './custom-style.css';
import 'react-toastify/dist/ReactToastify.css';
import RouteManagement from './route-management/index';
import { ToastContainer} from 'react-toastify';
import { Provider as ReduxProvider } from 'react-redux';
import Store from './redux/store/Store';

function App() {
  
  // console.log('MY_ENVIRONMENT_VARIABLE',process.env.MY_ENVIRONMENT_VARIABLE);

  return (
    <ReduxProvider store = {Store}>
    <ToastContainer />
      <RouteManagement />
    </ReduxProvider>
  );
}

export default App;
